@import-normalize; /* bring in normalize.css styles */

/* Font Imports */
@import url('https://fonts.googleapis.com/css?family=Poppins:light200');
@import url('https://fonts.googleapis.com/css?family=Poppins:regular400');
@import url('https://fonts.googleapis.com/css?family=Poppins:Medium500');
@import url('https://fonts.googleapis.com/css?family=Poppins:SemiBold600');
@import url('https://fonts.googleapis.com/css?family=Montserrat:regular400');
@import url('https://fonts.googleapis.com/css?family=Montserrat:Medium500');
@import url('https://fonts.googleapis.com/css?family=Montserrat:light300');
@import url('https://fonts.googleapis.com/css?family=Inter:light300');
@import url('https://fonts.googleapis.com/css?family=Inter:ExtraLight300');

/*html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, caption {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}*/

/* CSS RESET */
html,
body {
  padding: 0;
  margin: 0;
}

h1 {
    margin-top: 0px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Color Definitions */
:root {
    --vsblPurple: #5560F1;
    --hoverGrey: #626060;
    --hoverPurple: #7780f4;
    --offPurple: #EEEFFE;
    --secondaryPurple: #EEEFFE;
    --lightV: #bcc0fc;
    --mediumV: #7e85ff;
    --darkV: #5460f2;
    --manateeGrey: #5C6373;
    --titleFadeInDelay: .5s;
    --lightPurple: #BBBFFC;
    --lightYellowV: #FFC467;
    --mediumYellowV: #FFC467;
}

html {
    overflow: hidden;
    height: 100%;
}

body {
    height: calc(100vh + 20px);
    overflow: auto;
    background: white;
    margin-top: -20px;
    overflow-y: auto;
    overflow-x: hidden;
}

.loadingState {
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 0 !important;
}

.firstHeroUnit {
    display: flex;
    height: calc(95vh);
    width: 100%;
    min-height: 700px;
    background: url('../bin/images/vsbl_background.png');
    background-color: var(--vsblPurple);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

html body .pricingPage .firstHeroUnit {
    height: 250px !important;
    width: 100%;
    min-height: auto;
}
html body .pricingPage .getStarted{
    display: none;
}

.pricingPage .homeh2 {
    width: 70vw;
    margin: 0 auto;
    text-align: center;
    max-width: 1000px;
    margin-top: 60px;
}

.pricingPage .homeh2 h2 {
    color: var(--vsblPurple);
    font-family: poppins;
    margin-bottom: 15px !important;
    font-size: 52px;
    font-weight: 500;
}

.pricingPage .homeh2 p {
    font-family: Inter;
    font-size: 18px;
    color: #838383;
}

.pricingFeaturesExplainH2 {
    width: 70vw;
    margin: 0 auto;
    text-align: center;
    max-width: 1000px;
    margin-top: 150px;
}

.pricingFeaturesExplainH2 h2 {
    color: var(--vsblPurple);
    font-family: poppins;
    margin-bottom: 15px !important;
    font-size: 52px;
    font-weight: 500;
}

.featuresTableContainer {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    align-items: center;
}

.featureTable {
    margin-bottom: 140px;
    display: flex;
    flex-direction: column;
}

.pricingHeaderRow {
    margin-bottom: 15px;
}

.pricingHeaderRow:not(:first-child) {
    margin-top: 60px;
}

.pricingHeaderRow span {
    color: #1c1c1c;
    font-family: poppins;
    font-size: 32px;
    font-weight: 500;
}

.pricingFeatureRow {
    border-bottom: 1px solid #e3e3e3;
    padding-top: 15px;
    margin-bottom: 4px;
    padding-bottom: 15px;
}

.pricingFeatureRow span {
    color: #212121;
    font-family: inter;
    font-size: 18px;
    font-weight: 500;
}

@media (max-width: 900px) { 
    .featureTable {
        padding-left: 35px !important;
        padding-right: 35px !important;
    }
}

.homeFirstHeroUnitContent h1 {
    font-weight: 600;
}
.homeFirstHeroUnitContentPromotional h1 {
    font-weight: 600;
}

.wideFirstHeroUnit {
    
}

.centerVideoFrame {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 160px;
}

.centerVideoFrameContainer {
    width: 100%;
    max-width: 1000px;
    height: 500px;
}

.centerVideoFrame iframe {
    width: 100%;
    height: 100%;
}

.firstHeroUnitContainerLeft {
    padding: 0px 0px 0px 70px;
    width: 50%;
}

.firstHeroUnitContainerRight {
    width: 50%;
    height: 100%;
}

.homeFirstHeroUnit {
    width: 100vw;
    height: calc(100vh);
    background: url('../bin/images/homePageHeroUnitBackground.jpg');
    background-color: var(--vsblPurple);
    background-size: cover;
    background-repeat: no-repeat;
}

.homeFirstHeroUnitContent {
    width: 860px;
    position: relative;
    opacity: 0;
    animation: moveTop 0.5s linear;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    margin: 0 auto;
    text-align: center;
    padding-top: 23vh;

    font-family: 'Poppins';
    display: flex;
    flex-direction: column;
    color: white;
    font-weight: 600;
}

.smallParan {
    font-size: 35px;
    font-weight: 600;
}

.homeFirstHeroUnitContentPromotional {
    width: 860px;
    position: relative;
    opacity: 0;
    animation: moveTop 0.5s linear;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    margin: 0 auto;
    text-align: center;
    padding-top: 23vh;
    font-family: 'Poppins';
    display: flex;
    flex-direction: column;
    color: white;
    font-weight: 600;
}

.vsblDecoration {
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    font-size: 30px;
    color: #5560F1;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-right: 15px;
    margin-left: 15px;
}

.promotionalLine1 {
    margin-bottom: 15px;    
    font-size: 55px;
    display: flex;
    justify-content: center;
}

.promotionalLine2 {
    font-size: 75px;
    background: linear-gradient(to top left, #ff75c3, #c0ff93, #70e2ff, #e4c5ff, #ffe83f);
    background-size: 200% 200%;
    animation: gradientAnimation 4s ease infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.promotionalLine3 {
    font-size: 25px;
    margin-top: 15px;
    margin-bottom: 25px;
}

.SignUp {

}

.SignUpButton {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.SignUpLeft {

}

.SignUpRight {
    max-width: 75px;
}

@media (max-width: 664px) { 
    @media only screen 
        and (-webkit-device-pixel-ratio: 3) { 
            .homeFirstHeroUnit .homeFirstHeroUnitContent {
                opacity: 1 !important;
                animation: moveTop2 0.5s linear;
            }
            .homeFirstHeroUnit .homeFirstHeroUnitContentPromotional {
                opacity: 1 !important;
                animation: moveTop2 0.5s linear;
            }
            .homeFirstHeroUnitContent h1 {
                font-size: 48px;
            }
            .homeFirstHeroUnitContentPromotional h1 {
                font-size: 48px;
            }
            @keyframes moveTop2 {
                to {
                    padding-top: 18vh !important;
                    opacity: 1;
                }
            }
        }

    .contentBody .homeFirstHeroUnit .homeFirstHeroUnitContent {
        animation: none;
        opacity: 1 !important;
    }
    .contentBody .homeFirstHeroUnit .homeFirstHeroUnitContentPromotional {
        animation: none;
        opacity: 1 !important;
    }
    .homeFirstHeroUnitContentPromotional h1 {
        font-size: 48px;
    }
    .homeFirstHeroUnitContent h1 {
        font-size: 48px;
    }

    .homeFirstHeroUnitContent {
        top: 26vh;
        padding-top: 0px;
    }
    .homeFirstHeroUnitContentPromotional {
        top: 26vh;
        padding-top: 0px;
    }
    .whatYouWillGetContainer {
        flex-direction: column;
    }
    .whatYouGet {
        margin-right: 0px !important;
    }
    .whatYouGet:not(:last-child) {
        margin-bottom: 50px;
    }
}

.topHeroUnitImage{
    display: flex;
    justify-content: center;
    width: 46vw;
    max-height: 88vh;
    float: right;
    padding-right: 0px;
    padding-top: 9vh;
    margin-top: 4vh;
    opacity: 0;
    animation: nonHomeImgFadeIn 0.8s  linear;
    animation-delay: 0.6s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}

@media (max-width: 1200px) { 
    .firstHeroUnitContainerRight .topHeroUnitImage {
        padding-top: 13vh !important;
    }
}

@media (max-width: 900px) { 
    .centerVideoFrame {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
    .firstHeroUnit .firstHeroUnitContainerRight {
        display: none;
    }
    .firstHeroUnit .firstHeroUnitContainerLeft {
        width: 906%;
        padding: 0px 0px 0px 0px;
    }
}

@media (max-width: 550px) { 
    .displayBoxContentRight img {
        max-width: 400px;
    }

    .homeFirstHeroUnitContentPromotional .promotionalLine2 {
        font-size: 58px !important;
    }

    .homeFirstHeroUnitContentPromotional .promotionalLine3 {
        font-size: 19px !important;
    }
}

@keyframes nonHomeImgFadeIn {
    to {
        padding-right: 45px;
        opacity: 1;
    }
}

.topHeroUnitImage img {
    max-height: 79vh;
    max-width: 100%;
}

.homeFirstHeroUnitContent h1 {
    font-size: 58px;
    margin-bottom: 0px;
}
.homeFirstHeroUnitContentPromotional h1 {
    font-size: 58px;
    margin-bottom: 0px;
}

.homeFirstHeroUnitContent p {
    margin-bottom: 34px;
    font-family: Inter;
    color: white;
    font-size: 24px;
    margin-top: 20px;
    line-height: 36px;
}
.homeFirstHeroUnitContentPromotional p {
    margin-bottom: 34px;
    font-family: Inter;
    color: white;
    font-size: 24px;
    margin-top: 20px;
    line-height: 36px;
}

.homeIntroParagraph {
    font-size: 21px;
    font-family: Inter;
    color: #fff;
    font-weight: 300;
}

.homeGetStarted {
    font-size: 23px;
    font-family: montserrat;
    font-weight: 600;
    color: var(--vsblPurple);
    background-color: #FFFFFF;
    width: 200px;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    margin: 0 auto;
}

.homePage .homeh2 {
    width: 70vw;
    margin: 0 auto;
    text-align: center;
    max-width: 1000px;
}

.homePage .homeh2 h2{
    font-family: poppins;
    margin-top: 100px;
    margin-bottom: 0px;
    font-size: 32px;
    line-height: 44.8px;
    color: #15161A;
    font-weight: 500;
}

.prosList {
    display: flex;
    justify-content: space-between;
    width: 85vw;
    margin: 0 auto;
    margin-top: 75px;
    text-align: center;
    max-width: 1300px;
}

.prosList svg {
    width: 50px;
    height: 40px;
}

.prosBox {
    border-radius: 10px;
    border: 1px solid;
    border-color: #EFF1F4;
    border-radius: 18px;
    padding: 12px;
    width: 362px;
    height: 278px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.prosBox:not(:last-child) {
    margin-right: 60px;
}

.bilingualExperience {

}

.prosIcon {
    width: 75px;
    margin: 0 auto;
    height: 75px;
    border-radius: 40px;
}

.prosIconWidth {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prosIcon img {
    width: 45px;
}

.prosIcon svg {
    font-size: 65px;
}

.bilingualExperience .prosIcon{
    background-color: #32D583;
}

.multiplatformAccess .prosIcon {
    background-color: #FD853A;
}

.yourWayEveryWay .prosIcon {
    background-color: #36BFFA;
}

.integrations .prosIcon {
    background-color: #FFC467;
}

.teamMemberToOwner .prosIcon {
    background-color: #F670C7;
}

.controlWhatYourTeamSees .prosIcon {
    background-color: #717BBC;
}

.prosTitle {
    font-size: 20px;
    margin-top: 15px;
    font-family: 'Poppins';
    line-height: 28px;
    color: #15161A;
    font-weight: 500;
}

.prosBlurb {
    font-size: 16px;
    color: #5C6373;
    font-family: Inter;
    margin-top: 15px;
}

.featuresDisplayBoxContainer {
    width: 100%;
    margin-top: 100px;
    max-width: 1400px;
    margin: 160px auto 0 auto;
    padding-left: 20px;
    padding-right: 20px;
}

.featuresDisplayBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.displayBoxTopNav {
    background-color: var(--vsblPurple);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    justify-content: center;
    display: flex;
}

.displayBoxTopNav ul {
    margin: 0;
    padding-inline-start: 0px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
    padding: 20px;
    -webkit-overflow-scrolling: touch;
}

@media (min-width: 1330px) { 
    .displayBoxTopNav ul {
        overflow-x: hidden;
    }
}

.displayBoxTopNav ul li:first-child {

}

.displayBoxTopNav .featureActiveTab {
    background-color: white;
    color: var(--vsblPurple);
}

.activeFeatureBox .displayBoxContentLeft, .activeFeatureBox .displayBoxContentRight {
    display: block;
}

.activeFeatureBox .displayBoxContentLeft {
    width: 80%;
    height: auto;
    margin-left: 40px;
}

.activeFeatureBox .displayBoxContentRight {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 35px;
    margin-bottom: 25px;
}

@media (max-width: 664px) { 
    @media only screen 
        and (-webkit-device-pixel-ratio: 3) { 
        
        .displayBoxContentLeft {
            margin-bottom: 25px;
        }

        .activeFeatureBox .displayBoxContentRight {
            margin-bottom: 25px;
            width: 100% !important;
        }

        .activeFeatureBox .displayBoxContentRight img {
            max-height: 340px;
        }
    }
}

.activeFeatureBox {
    display: flex !important;
}

.displayBoxContentRight img {
    height: 500px;
}

.activeFeatureBox {
    
}

.displayBoxContainer {

}

.displayBoxContainerInner {
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    display: none;
}

@media (max-width: 800px) { 
    .displayBoxContainerInner {
        flex-direction: column !important;
        padding-top: 50px;
    }
    .displayBoxContainer {
        height: 100% !important;
    }
}

.displayBoxContentLeft {
    display: none;
}

.displayBoxContentLeftInner {

}

.displayBoxTitle {
    font-family: poppins;
    font-size: 28px;
    color: #15161A;
    line-height: 43.2px;
    font-weight: 600;
    margin-bottom: 18px;
}

.displayBoxText {
    font-family: inter;
    font-size: 18px;
    margin-bottom: 18px;
    line-height: 25.2px;
    font-weight: 300;
}

.displayBoxLearnMore {
    font-family: poppins;
    font-size: 18px;
    color: var(--vsblPurple);
    cursor: pointer;
}

.displayBoxContentRight {
    display: none;
}

.displayBoxSetups {

}

.displayBoxShifts {

}

.displayBoxAccountability {

}

.displayBoxVendors {

}

.displayBoxReporting {

}

.displayBoxRewards {

}

.displayBoxChecklistsAndTasks {

}

.displayBoxHomeScreen {

}

.displayTeamAndScoreboard {

}


/* Firefox */
.displayBoxTopNav ul {
    scrollbar-width: thin;
    scrollbar-color: #EEEFFE #B8C0C2;
  }
  
  /* Chrome, Edge and Safari */
  .displayBoxTopNav ul::-webkit-scrollbar {
    height: 11px;
    width: 11px;
  }
  .displayBoxTopNav ul::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #B8C0C2;
  }
  
  .displayBoxTopNav ul::-webkit-scrollbar-track:hover {
    background-color: #B8C0C2;
  }
  
  .displayBoxTopNav ul::-webkit-scrollbar-track:active {
    background-color: #B8C0C2;
  }
  
  .displayBoxTopNav ul::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #EEEFFE;
  }
  
  .displayBoxTopNav ul::-webkit-scrollbar-thumb:hover {
    background-color: #EEEFFE;
  }
  
  .displayBoxTopNav ul::-webkit-scrollbar-thumb:active {
    background-color: #EEEFFE;
  }



.displayBoxTopNav ul li {
    font-size: 15px;
    padding: 20px;
    display: inline;
    font-family: poppins;
    color: white;
}

.displayBoxTopNav ul li:hover {
    background-color: var(--offPurple);
    color: black;
    cursor: pointer;
}

.displayBoxContainer {
    height: 600px;
    border-left: 2px solid var(--vsblPurple);
    border-right: 2px solid var(--vsblPurple);
    border-bottom: 2px solid var(--vsblPurple);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.featureDisplayBoxInner {

}



.homePage .testimonialsSection {
    margin-top: 75px;
}

.testimonialsSection p {
    font-family: Inter;
    font-size: 17px;
}

.testimonialContainer {
    display: flex;
    width: 85vw;
    margin: 0 auto;
    margin-top: 80px;
}

.testimonial {
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    padding: 24px;
    border: 1px solid #EFF1F4;
    border-radius: 10px;
}

@media (max-width: 800px) { 
    .testimonialContainer {
        flex-direction: column !important;
    }
    .testimonial:not(:last-child) {
        margin-bottom: 25px;
    }
}

@media (min-width: 800px) { 
    .testimonial:not(:last-child) {
        margin-right: 20px;
    }
}

.testimonialTopDiv {
    display: flex;
    align-items: center;
}

.testimonialTopDivRightSide {

}

.testimonialPic {
    width: 44px;
    height: 44px;
    margin-right: 20px;
}

.testimonialPic img {
    width: 100%;
    height: 100%;
}

.testimonialFullName {
    font-family: poppins;
    font-size: 16px;
}

.testimonialLocation {
    font-family: Inter;
    font-size: 14px;
    margin-top: 5px;
}

.testimonialBottomDiv {

}

.comment {
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    margin-top: 24px;
    line-height: 1.7;
}

.testimonialRating {
    margin-top: 24px;
}

.testimonialRating svg {
    fill: gold;
}

.contentBody .whatYouWillGet h2 {
    font-size: 36px;
    font-weight: 500;
}

.whatYouWillGetContainer {
    display: flex;
    width: 70vw;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 200px;
}

.whatYouGet {
    padding: 15px;
    background-color: #FAFAFA;
    border-radius: 12px;
    text-align: center;
}

.whatYouGet:not(:last-child){
    margin-right: 40px;
}

.whatYouGetTop {
    font-family: poppins;
    font-size: 18px;
    font-weight: 500;
    align-items: center;
    display: flex;
    justify-content: center;
}

.whatYouGetBottom {
    margin-top: 10px;
    color: var(--manateeGrey);
    font-family: inter;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
} 

.whatYouGetSVG {
    display: flex;
    align-items: center;
}

.whatYouGetSVG svg {
    fill: var(--vsblPurple);
    margin-right: 20px;
    width: 20px;
}

.whatYouGetTitle {
    font-size: 18px;
    line-height: 25.2px;
}

.homePage .plan h2 {
    color: var(--vsblPurple);
    font-family: poppins;
    font-size: 32px;
    font-weight: 500;
}

.homePage .plan p {
    font-family: Inter;
    font-size: 18px;
    color: #838383;
}





@media (max-width: 930px) { 
    .masterCardsContainer {
        flex-direction: column !important;
    }
    .card {
        margin: 0 auto !important;
    }

    .contentBody .promotionAlert {
        width: 365px !important;
        text-align: center;
    }
    .waste {
        order: 2
    }
    .vsbl_service {
        order: 1;
    }
}

@media (max-width: 930px) { 
    @media only screen 
        and (-webkit-device-pixel-ratio: 3) { 
            .contentBody .masterCardsContainer .card {
                width: 365px !important;
            }

            .contentBody .promotionAlert {
                width: 365px !important;
            }

            .vsbl_service::before {
                width: 369px !important;
            }
            
            .vsbl_service::after {
                width: 365px !important;
            }

    }
}

 

@property --rotate {
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
}

:root {
    --card-height: 65vh;
    --card-width: calc(var(--card-height) / 1.5);
}

.promotionAlert {
    padding: 20px;
    margin-bottom: 40px;
    background-color: #444444;
    border-radius: 7px;
    color: #ededed;
    font-family: poppins;
    font-size: 17px;
    font-weight: 600;
}

.promotionAlertInner {
    
}

.cards_container {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    align-items: center;
}

.masterCardsContainer {
    display: flex;
    flex-direction: row;
    gap: 100px;
    justify-content: center;
}

.waste .vsbl_get_started {
    position: relative;
    bottom: -17px;
}

.vsbl_service .vsbl_get_started {
    position: relative;
    bottom: -20px !important;
}

.card {
    background: #ffffff;
    width: 385px;
    height: 769px;
    padding: 30px;
    position: relative;
    border-radius: 18px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #000000;
    justify-content: flex-start
}

.waste {
    border: 1px solid #cdcdcd;
}

.wasteTitle {
    font-family: Inter;
    font-size: 32px;
    font-weight: bold;
    color: #FFC467;
    margin-left: 4px;
}

.card .colorChange {
    fill: var(--vsblPurple)!important;
}

.card .darkV {
    fill: var(--darkV)!important;
}
.card .lightV {
    fill: var(--lightV)!important;
}
.card .mediumV {
    fill: var(--mediumV)!important;
}

.logoInner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.service_description {
    text-align: left;
    color: #5C6373;
    margin-bottom: 34px;
    font-size: 16px;
    font-family: inter;
}

.discountBox {
    font-family: poppins;
    font-size: 20px;
    color: #0E9F5B;
    border: 2px solid #32D583;
    border-radius: 5px;
    padding: 5px;
}

.discountBoxInner {

}

.service_price {
    text-align: left;
    font-family: poppins;
    font-size: 18px;
}

.promotionalServicePrice {
    display: flex;
    flex-direction: column;
}

.annualCard {
    font-family: poppins;
    font-size: 35px;
    font-weight: 600;
    color: #2e2e2e;
}

.promotionalPricing {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.pricingNewLabel {
    color: white;
    background-color: black;
    padding: 4px;
    font-family: poppins;
    font-size: 12px;
    border-radius: 10px;
    margin-left: 5px;
}

.service_large_price {
    font-size: 32px;
    font-weight: bold;
    font-family: Poppins;
}

.service_price_promotion {
    font-size: 32px;
    font-weight: bold;
    font-family: Poppins;
}

.serviceOldPrice {
    text-decoration: line-through;
    color: #747474;
}

.prePromotionPricing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.promotionEndDate {
    font-family: poppins;
    font-size: 15px;
    color: #747474;
}

.most_popular {
    position: absolute;
    top: -15px;
    background-color: #5560f1;
    color: #ffffff;
    padding: 8px;
    width: 97px;
    font-size: 12px;
    font-family: 'Inter';
    font-weight: bold;
    border-radius: 20px;
}

.card_inner_top {
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 34px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 250px;
    padding-bottom: 20px;
}

.card_inner_bottom {
    height: 60%;
    width: 100%;
    text-align: left;
}

.pre_ul_text {
    color: #5C6373;
    font-size: 18px;
    margin-bottom: 20px;
    font-family: inter;
    font-weight: lighter;
}

.card_inner_bottom ul {
    list-style-type: none;
    font-family: inter;
    font-weight: regular;
    color: #000;
}

.card_inner_bottom li {
    color: #15161A
}

.card_inner_bottom li:not(:last-child) {
    margin-bottom: 20px;
}

.card .logo svg {
    height: 32px;
}

.card .logo svg {
    margin-top: 4px;
}

.card_inner_bottom .included svg {
    fill: #07D0B1;
    margin-right: 15px;
    width: 15px;
}

.card_inner_bottom svg {
    fill: #5C5C5C;
    margin-right: 15px;
}

.logo {
    text-align: left;
    margin-bottom: 34px;
    display: flex;
}

.vsbl_service::before {
    content: "";
    /*width: 101%;*/
    width: 389px;
    height: 100.6%;
    border-radius: 12px;
    background-image: linear-gradient(var(--rotate), #07D0B1, #7d85fe 43%, #5560F1);
    position: absolute;
    z-index: -1;
    top: -.2%;
    left: -.5%;
    animation: spin 4s linear infinite;
}

.vsbl_service::after {
    position: absolute;
    content: "";
    /* top: calc(var(--card-height) / 6); */
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    /*width: 82%;*/
    width: 385;
    margin: 0 auto;
    transform: scale(1);
    filter: blur(calc(var(--card-height) / 30));
    background-image: linear-gradient( var(--rotate) , #07D0B1, #7d85fe 43%, #5560F1);
    opacity: .5;
    transition: opacity .5s;
    animation: spin 4s linear infinite;
}



@keyframes spin {
    0% {
        --rotate: 0deg;
    }
    100% {
        --rotate: 360deg;
    }
}

.get_started {
    font-size: 16px;
    line-height: 21px;
    font-family: 'Inter';
    font-weight: Medium;
    text-align: center;
    padding: 20px;
    width: 70%;
    margin: 0 auto;
    border-radius: 40px;
    border-radius: 40px;
}

.get_started:hover {
    background-color: white;
    border: 2px solid #5560f1;
    color: #5560f1;
    cursor: pointer;
}

.waste_get_started {
    width: 100%;
    height: auto;
}

.waste_get_started_button {
    border: 1px solid #5560f1;
    color: #5560f1;
    background-color: white;
    font-weight: 600;
    font-family: poppins;
    font-size: 18px;
}

.vsbl_get_started_button {
    background-color: #5560f1;
    color: white;
    font-weight: 600;
    font-family: poppins;
    font-size: 18px;
}






















@media (max-width: 850px) { 
    .prosList .prosBox:not(:last-child) {
        margin-right: 20px;
    }
    .homeFirstHeroUnitContent {
        width: 100%;
        padding-left: 35px;
        padding-right: 35px;
    }
    .homeFirstHeroUnitContentPromotional {
        width: 100%;
        padding-left: 35px;
        padding-right: 35px;
    }

    .homeFirstHeroUnitContentPromotional h1 {
        font-size: 45px;
        margin-bottom: 0px;
    }
    .homeFirstHeroUnitContent h1 {
        font-size: 45px;
        margin-bottom: 0px;
    }

    .homeFirstHeroUnitContentPromotional p {
        font-size: 19px;
    }
    .homeFirstHeroUnitContent p {
        font-size: 19px;
    }
}

/* Universal Button Styles */
button.MuiButton-contained {
    background-color: var(--vsblPurple) !important;
}

.logoContainer {

}

/* 
.headerContainer {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 32;
    padding-left: 70px;
    padding-right: 70px;
    transition: background-color 0.3s ease;
}
*/

.headerContainer {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 32;
    transition: background-color 0.3s ease;
}

.alertBar {
    width: 100%;
    height: 45px;
    background-color: #313895;
    display: none;
    font-family: poppins;
    font-size: 16px;

    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.09);
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.09);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
}

.fullNavBar {
    padding-left: 70px;
    padding-right: 70px;
}

.alertBarInner{
    text-align: center;
}

.alertBarToggle .alertBar {
    display: flex;
}

.widthFull {
    width: 100%;
}

.navBar ul {
    margin-top: 0px;
    margin-bottom: 0px;
}

.navbar li {
    font-size: 15px;
    display: inline;
    font-family: poppins;
    padding: 15px;
    padding-bottom: 23px;
}

.headerContainer.scrolled {
    background-color: #ffffff;
    height: max-content;
    -webkit-box-shadow: 0px 2px 0px 0px rgba(85,96,241,0.10); 
    box-shadow: 0px 2px 0px 0px rgba(85,96,241,0.10);
}

.noClick {
    cursor:default;
}

.scrolled .navButton li {
    color: #000;
}

.scrolled .navButton li svg path {
    fill: #000;
}

.login a {
    color: #fff;
    font-family: poppins;
}

.scrolled .login a {
    color: #000;
}

.headerContainer nav {
    display: flex;
}

.topNav nav {
    align-items: center;
}

.headerContainer.scrolled .signUpButton {
    background-color: var(--vsblPurple);
    color: #fff;
}

.headerContainer.scrolled .signUpButton:hover {
    background-color: var(--offPurple);
    border-color: var(--hoverPurple);
    color: var(--darkV);
}

.headerContainer.scrolled .colorChange {
    fill: var(--vsblPurple);
}

.headerContainer.scrolled .lightV {
    fill: var(--lightV);
}
.headerContainer.scrolled .mediumV {
    fill: var(--mediumV);
}
.headerContainer.scrolled .darkV {
    fill: var(--darkV);
}

.scrolled .logoContainer g{
    animation-name: moveLeft;
    animation-fill-mode: forwards;
    animation-duration: .2s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    transform: translateX(70px);
    opacity: 0;

    /*animation-name: spin;
    animation-duration: .4s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    transform-origin: -1% -1%;
    rotate: 0deg;*/
}

@keyframes moveLeft {
    to {
        transform: translateX(0px);
        opacity: 1;
    }
}

/*@keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(365deg);}
}*/

.spinner {

}


.navButton a {
    text-decoration: none;
    padding-left: 0px;
    padding-right: 0px;
}

.navButton li {
    color: #FFFFFF;
    border-bottom: 0px solid transparent;
    /*transition: padding-left 0.3s ease, padding-right 0.3s ease; /* This ensures smooth transitions */
    transition: border-bottom 0.3s ease;
}

.navbar li:not(:last-child) {
    /*margin-right: 3vw;*/
}

.logoContainer {

}

.headerItem {
    margin-top: 0;
    margin-bottom: 0;
}

.topNav nav .login {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
}

.headerContainer.scrolled .login {
    border: 1px solid transparent;
}

.login .loginIcon {
    margin-left: 5px;
    margin-top: 5px;
}

.headerContainer .topNav nav .login:hover {
    border: 1px solid var(--vsblPurple);
    border-radius: 8px;
    background-color: var(--secondaryPurple);
    cursor: pointer;
}

.scrolled .login {
    color: #000;
}

.topNav nav .login:hover div {
    color: var(--vsblPurple) !important;
}

.login {
    font-family: poppins;
    font-weight: 500;
    display: flex;
    white-space: nowrap;
    margin-right: 50px;
    color: #fff;
}

.signUpButton {
    font-size: 16px;
    font-family: montserrat;
    font-weight: 600;
    color: var(--vsblPurple);
    background-color: #FFFFFF;
    width: 105px;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    text-align: center;
}

.signUpButton:hover {
    background-color: var(--offPurple);
    border-color: var(--hoverPurple);
    color: var(--darkV);
}

.activeTab {
    color: var(--vsblPurple) !important;
}

.headerItem .activeTab svg path{
    fill: var(--vsblPurple) !important;
}

.subNavColumn .activeSubNavTab {
    background-color: var(--secondaryPurple) !important;
}

.subNavColumn .activeSubNavTab svg path { 
    fill: var(--vsblPurple);
}

.subNavColumn .activeSubNavTab .subNavElDesc {
    color: #2E2E2E;
}

.subNavColumn .subNavEl img {
    height: 18px;
}

@keyframes flip {
    to {
        transform: rotate(150deg);
    }
}

/* Hover styles for Nav bar */
.signUpButton:hover {
    background-color: var(--vsblPurple);
    color: #fff;
    border: 1px solid #fff;
}
.headerContainer .topNav .navButton li:hover{ 
    color: var(--vsblPurple) !important;
}

.headerContainer.scrolled .navButton li:hover {
    color: var(--vsblPurple) !important;
}

.scrolled .navButton li:hover svg path {
    fill: var(--vsblPurple) !important;
}

/* End hover styles for Nav Bar */
.topNav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    z-index: 32;
    height: 60px;
    align-items: center;
    transition: height 0.2s ease; 
}

.scrolled .topNav {
    height: 65px;
}
.bottomNav {
    border-top: 1px solid rgb(233, 233, 233);
    padding-top: 10px;
}

.gutter {
    width: 130px;
}

.subNav {
    display: flex;
}

.subNavInnerContainer {

}

.subNavColumn {
    width: max-content;
}

.subNavColumn:not(:last-child) {
    margin-right: 25px;
}

.subNavEl {
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.subNavEl:hover {
    background-color: var(--secondaryPurple);
}

.subNavEl:hover .subNavIcon svg path {
    fill: var(--vsblPurple);
}

.subNavElRight {
    padding-left: 15px;
}

.subNavElTop {
    display: flex;
    align-items: center;
}

.subNavIcon {
    margin-top: 1px;
}

.subNavIcon svg path {
    fill: #dfdfdf;
}

.subNavIcon .rewardsSVG {
    fill: white !important;
}

.subNavTitle {
    font-family: poppins;
    font-size: 16px;
}

.subNavNewLabel {
    background-color: black;
    color: white;
    font-family: poppins;
    font-size: 12px;
    border-radius: 12px;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 20px;
}

.subNavElBottom {
    margin-top: 10px;
}

.subNavElDesc {
    font-family: Inter;
    color: #5C6373;
    font-size: 14px;
}

.linearWipe {
    text-align: center;
    
    background: linear-gradient(to right, #FFF 20%, #FF0 40%, #FF0 60%, #FFF 80%);
    background-size: 200% auto;
    
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    animation: shine 5s linear infinite;
    animation-delay: 0;
}

@keyframes shine {
    to {
        background-position: 200% center;
    }
}

/* Mobile Nav Bar */

.minNavBar {

}

.mobileSpacer {
    flex-grow: 3;
}

.minNavBar .signUp {
    margin-right: 25px;
}

.hamburger {
    color: white;
    cursor: pointer;
}

.hamburger svg {
    font-size: 35px;
}

.headerContainer.scrolled .hamburger {
    color: #000;
}

.mobileNavBar {
    position: fixed;
    top: 0px;
    /*left: 800px;*/
    background-color: white;
    width: 100%;
    height: 100%;
    left: 0px;
    display: none;
    overflow-y: scroll;
}

.displayMobileNavBar {
    display: block !important;
}



.mobileNavBarMain {
    list-style-type: none;
    padding-inline-start: 0;
    width: 85%;
    margin: 0 auto;
}

.topLevel:first-child{
    border-top: 1px solid #e5e5e5;
}

.mobileNavBarMain .topLevel {
    padding: 17px;
    font-family: poppins;
    font-size: 17px;
    border-bottom: 1px solid #e5e5e5;
    font-weight: bold;
}

.mobileNavBarMainSubNav {
    list-style-type: none;
    padding-inline-start: 0;
    /*display: none;*/
    padding-left: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 15px;
}

.mobileNavBarLogo svg path {
    fill: var(--vsblPurple) !important;
}

.mobileNavBar .mobileNavBarMain .mobileNavBarMainSubNav li {
    padding-bottom: 10px;
    padding-left: 15px;
    padding-top: 10px;
    cursor:pointer;
}

.mobileNavBar .mobileNavBarMain .mobileNavBarMainSubNav li:hover {
    background-color: var(--offPurple);
}

.mobileNavBar .mobileNavBarMain .mobileNavBarMainSubNav li:hover .mobileNavSubNavImage svg path {
    fill: var(--vsblPurple) !important;
}

.activeFeature {
    background-color: var(--secondaryPurple);
}

.mobileNavBarMainSubNav .activeSubNavTab {
    background-color: var(--secondaryPurple);
}

.mobileNavBarMainSubNavContainer {
    display: flex;
}

.mobileNavSubNavLeft {
    margin-right: 20px;
    padding-top: 7px;
}

.mobileNavSubNavRight {

}

.mobileNavSubNavTitle {
    font-family: poppins;
    font-size: 15px;
}

.mobileNavSubNavText {
    font-family: inter;
    font-size: 13px;
    margin-top: 6px;
    font-weight: 500;
    color: var(--manateeGrey);
}

.mobileNavSubNavImage {

}

.mobileNavSubNavImage svg, .mobileNavSubNavImage svg path {
    fill: #dfdfdf;
}

.mobileNavSubNavImage img {
    width: 18px;
}

.mobileNavBarTop {
    padding: 20px;
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
}

.mobileNavBarLogo {

}

.mobileNavBarBottom {

}

.activeSubNavTab .mobileNavBarMainSubNavContainer {
}

.topLevel {
    cursor:pointer;
}

.mobileNavBarBottom .mobileNavBarMain nav {
    display: block !important;
}

.mobileNavCloseButton {
    font-family: inter;
    font-size: 30px;
    width: 50px;
    height: 50px;
    text-align: center;
    color: var(--manateeGrey);
    cursor: pointer;
}

.testimonialsMobile {
    border-top: 1px solid #e5e5e5;
}

@media (max-width: 400px) { 
    .minNavBar .signUp {
        margin-right: 15px;
    }
}

@media (min-width: 900px) {

    .firstHeroUnit .firstHeroUnitContent {
        padding: 29vh 0vw 0px 0vw;
    }

}

@media (max-width: 665px) { 
    .firstHeroUnit .firstHeroUnitContent {
        width: 100%;
        position: relative;
        margin: 0 auto;
        padding: 0vh 6vw 0px 6vw;
        top: 30vh;
        left: 0px;
        opacity: 0;
        animation: moveTop .9s linear;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }

    .firstHeroUnit h1 {
        font-size: 7vw;
    }

    .contentBody {
        margin-top: 20px;
    }



    .contentBody .firstHeroUnit {
        /*background: url('../bin/images/vsbl_background.png') -600px;*/
        background-position: -600px !important;
        background-size: cover;
    }

    html .footer .learnMore {
        width: 85vw !important;
        text-align: center;
    }

    .contentBody .monolith:last-child {
        padding-bottom: 150px;
    }

    .learnMore .learnMoreLeft .learnMoreTitle {
        font-size: 30px;
    }

    .learnMore .learnMoreLeft .learnMoreText {
        color: #eaeaea;
        font-size: 15px;
        margin-top: 10px;
    }

    @keyframes moveTop {
        to {
            top: 32vh;
            opacity: 1;
        }
    }
}

@media (max-width: 950px) { 
    .fullNavBar {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

@media (max-width: 1150px) { 
    .contentBody .monolith {
        /* width: 950px; */
    }
}

@media (min-width: 1500px) { 
    .contentBody .monolith {
        /* width: 950px; */
    }
}

@media (max-width: 1000px) { 
    .contentBody .monolith {
        width: 100%;
        height: auto;
        flex-direction: column;
        display: flex;
        align-items: center;
    }

    .monolithInner {
        flex-direction: column !important;
    }
    .rightImgMonolith .monolithInner {
        padding-left: 0px !important;
    }

    .contentBody .firstHeroUnit {
        background: url('../bin/images/vsbl_background.png') no-repeat -400px;
        background-size: cover;
        background-position: right !important;
    }

    .contentBody .monolith .imageContainer {
        order: 2;
    }

    .contentBody .monolith .imgSetupTemplate {
        width: 100%;
        height: 100%;
        max-width: 575px;
    }

    .contentBody .monolith .featureOverview {
        order: 1;
    }

    .contentBody .monolith .featureOverview {
        width: 90%;
        height: 100%;
        margin: 0 auto;
        padding: 0px 20px 0px 20px;
    }

    .contentBody .monolith .imageContainer {
        width: auto;
        justify-content: center;
        margin-top: 27px;
        padding-right: 0vw !important;
    }

    .footer .learnMore {
        width: 75vw !important;
    }
}

@media (max-width: 1260px) { 
    .learnMore {
        width: 75vw !important;
    }
}

@media (min-width: 665px) { 
    .firstHeroUnitContent {
        padding: 29vh 8vw 0px 8vw;
        opacity: 0;
        animation: moveTop var(--titleFadeInDelay) linear;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        animation-timing-function: ease-in-out;
    }

    @keyframes moveTop {
        to {
            padding-top: 30vh;
            opacity: 1;
        }
    }
}

@media (max-width: 800px) { 
    .contentBody .prosList {
        flex-direction: column;
        align-items: center;
    }

    .contentBody .prosList .prosBox {
        margin-right: 0px;
        margin-bottom: 25px;
    }

    .footer .footerContent {
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;
    }

    .footerContent .footerLeft {
        width: 60vw;
        display: contents;
    }

    .footerContent .footerRight {
        width: 80vw;
        padding-right: 0px;
    }

    .footerContent .footerLeft .socials a {
        margin-right: 15px;
    }

    .footerContent .footerLeft .socials svg {
        height: 61px;
        width: 60px;
    }

    .footerContent .footerLeft p {
        font-size: 15px;
        padding: 0px 30px 0px 30px;
        text-align: center;
    }

    .footerContent .footerGutter {
        width: 100px;
        height: 50px;
    }

    .footer .subFooter {
        padding-left: 35px;
        padding-right: 35px;
        text-align: center;
    }
}

@media (max-width: 850px) {
    .footer .subFooter {
        flex-direction: column;
    }

    .footer .subFooterleft {
        margin-bottom: 20px;
    }
}

@media (max-width: 830px) { 
    .footer .learnMoreLeft {
        order: 1;
    }
    .footer .learnMoreRight {
        order: 2;
        margin-top: 30px;
    }
    
    .footer .learnMore {
        flex-direction: column;
        width: 80vw;
    }
}

.getStarted {
    font-size: 16px;
    font-family: montserrat;
    font-weight: 600;
    color: var(--vsblPurple);
    background-color: #FFFFFF;
    width: 200px;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    text-align: center;
    margin-top: 50px;
}

.monolith {
    padding-top: 100px;
    padding-bottom: 100px;
    justify-content: space-between;
    /*box-shadow: 0px 0px 0px 20px rgb(255 255 255 / 90%);*/
}

.wideMonolith {

}

.monolith:nth-of-type(1) {
    box-shadow: 0px 0px 0px 20px rgb(255 255 255 / 90%);
}

.monolithInner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
    max-width: 1200px;
    align-items: center;
}

.positiveReinforcement .imageContainer{
    padding-left: 150px;
}

.positiveReinforcement img {
    width: 100% !important;
}

.wideMonolith .monolithInner {
    width: 100%;
    max-width: 1400px;
}

.rightImgMonolith .monolithInner {
    padding-left: 30px;
}

.comingSoonHeroBlock .monolithInner {
    padding-left: 0px;
    justify-content: center;
    text-align: center;
}

.leftImgMonolith {
    background-color: #fff;
}

.rightImgMonolith {
    background-color: #FCFCFF;
}

.comingSoonHeroBlock .rightImgMonolith {
    padding-bottom: 150px;
}

.leftImgMonolith .imageContainer {

}

.leftImgMonolithInner .imageContainer {
    padding-right: 4vw !important;
    width: 100% !important;
}
.rightImgMonolithInner .imageContainer {
    padding-left: 4vw !important;
    width: 100% !important;
}

.leftImgMonolith .featureOverview {
}

.comingSoonHeroBlock .featureOverview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rightImgMonolith .featureOverview {
}

.rightImgMonolith .imageContainer {
    justify-content: right;
}

.imageContainer {
    width: 50%;
    display: flex;
}

.wideMonolith .imgSetupTemplate {
    width: 100% !important;
}

.featureOverview {
    width: 50%;
    height: 100%;
}

.comingSoonHeroBlock .featureOverview {
    width: 80%;
}

.wideMonolith .featureOverview {
    width: 55%;
}

.imgSetupTemplate {
    width: 400px;
}

.featureTag {
    font-family: montserrat;
    font-weight: 500;
    font-size: 16px;
    color: var(--vsblPurple);
    background-color: var(--offPurple);
    width: max-content;
    height: 35px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 20px;
    margin-bottom: 23px;
}

.featureTitle {
    font-family: poppins;
    font-weight: 500;
    font-size: 22px;
}

.featureBlurb {
    font-family: Inter;
    font-weight: 300;
    font-size: 20px;
    color: #4f4f4f;
}


.wasteContainer {
    display: flex;
    flex-direction: column;
}

.featureWasteTitle {
    font-family: poppins;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 20px;
}

.wastesubText {
    font-family: inter;
    font-size: 24px;
    font-weight: 200;
    margin-bottom:50px;
    text-align: center;
}

.wasteBoxes {
    display: flex;
}

.wasteBox {
    display: flex;
    flex-direction: row;
    background-color: #FCFCFC;
    border: 1px solid #efefef;
    padding: 20px;
    width: 300px;
    margin-right: 35px;
    align-items: center;
} 

.wasteBox:last-child {
    margin-bottom: 0px;
}


.rewardsContainer {
    flex-direction: column;
}

.rewardsContainerTop {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rewardsContainerTopInner {

}

.rewardsTopTag {
    font-family: montserrat;
    font-size: 20px;
    color: var(--vsblPurple);
    background-color: var(--secondaryPurple);
    padding: 4px;
    border-radius: 10px;
    font-weight: 400;
    max-width: 176px;
    text-align: center;
}

.rewardsTopText {
    font-family: poppins;
    padding-top: 15px;
    font-weight: 500;
    font-size: 22px;
}

.rewardsContainerBottom {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}

.rewardsContainerBottomInner {

}

.rewardsGamification {

}

.rewardsBlock {
    padding: 25px 45px 25px 45px;
    background-color: #FCFCFF;
    border: 1px solid #e7e7e7;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
}

.rewardsBlockTop {
    
}

.rewardsBlockTop img {
    width: 30px;
}

.rewardsBlockBottom {
    font-family: inter;
    font-size: 22px;
    color: var(--vsblPurple);
    font-weight: 900;
    margin-top: 9px;
}

.rewardsMiddleBlock {
    display: flex;
    align-items: center;
    padding-left: 45px;
    padding-right: 45px;
    width: auto;
}

.rewardsMiddleBlock img {
    width:37px;
}





@media (max-width: 1000px) {
    .rewardsMiddleBlock {
        padding-left: 0px;
        padding-right: 0px;
        justify-content: center;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .rewardsContainerBottom {
        flex-direction: column;
    }
    .wasteBoxes {
        flex-direction: column;
    }
    .wasteBox {
        margin-bottom: 50px;
        width:100%;
    }
}

.wasteBoxLeft {
    margin-right: 20px;
}

.wasteBoxLeft img {
    width: 46px;
}

.wasteBoxRight {

}

.wasteTextTop {
    font-family: poppins;
    font-size: 40px;
    color: #5560F1;
}

.wasteTextBottom {
    font-family: inter;
    font-size: 18px;
    color: var(--manateeGrey);
}











.footer {
    background-color: #222660;
}

.footer p {
    color: white;
    font-family: poppins;
    font-size: 14px;
}

.footerContent {
    display: flex;
    padding-left: 75px;
    padding-right: 75px;
    padding-bottom: 25px;
}

.scheduleMeetingButton {
    font-size: 16px;
    font-family: montserrat;
    font-weight: 600;
    color: var(--vsblPurple);
    background-color: #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
    padding: 18px;
    text-align: center;
}

.footerLeft {
    width: 18vw;
}

.footerContent .footerLeft .socials svg:hover rect {
    fill: var(--hoverGrey) !important;
    cursor: pointer;
}

.footerContent .footerLeft .socials svg:hover path {
    cursor: pointer;
}

.footerContent .footerLeft .socials svg {
    height: 41px;
    width: 40px;
}

.footerLeft svg:not(:last-child){
    margin-right: 20px;
}

.footerGutter {
    width: 100px;
}

.footerRight {
    width: 60vw;
    padding-right: 50px;
}

.footerLinks {

}

.footerLinks ul {
    color: white;
    font-family: poppins;
    font-size: 16px;
    list-style: none;
    padding-inline-start: 0px;
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    margin-bottom: 50px;
}

.footerLinks ul li {
    display: inline;
}

.footerLinks ul li:not(:last-child){
    margin-right: 15px;
}

.firstColumnSubLinks {
    width:max-content;
}

.firstColumnSubLinks ul {
    color: white;
    margin-top: 0;
    padding-left: 0;
    font-family: Inter;
    font-size: 14px;
}

.firstColumnSubLinks ul li {
    list-style: none;
    margin-bottom: 20px;
}

.subFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #191C48;
    color: white;
    font-family: Inter;
    font-size: 12px;
    padding-left: 75px;
    padding-right: 75px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.subFooterleft {

}

.subFooterRight {

}

.subFooterRight a:first-child{
    margin-right: 15px;    
}

.subFooterImgBanner:not(:last-child) {
    margin-right: 25px;
}

.learnMore {
    width: 75vw;
    max-width: 900px;
    background-color: #5560F1;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    padding: 38px;
    margin: 0 auto;
    position: relative;
    top: -80px;
    align-items: center;
}

.learnMoreLeft {
    text-align: left;
}

.learnMoreTitle {
    font-family: Poppins;
    font-size: 34px;
    color: white;
    margin-bottom: 29px;
}

.learnMoreText {
    font-family: Poppins;
    color: white;
    font-size: 18px;
}

.learnMoreRight {

}

.signUpButton {

}