h1 {
    font-size: 44pt;
    color: #ffffff;
    font-family: poppins;
    font-weight: 500;
}

h2 {

}

h3 {
    font-size: 20pt;
    line-height: 29px;
    font-family: montserrat;
    font-weight: 400;
    color: #fff;
}

h4 {

}

p.introParagraph {
    font-size: 21px;
    font-family: Inter;
    color: #fff;
    font-weight: 300;
}